import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from 'react';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import client from 'src/utils/GQLCompanyClient';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import useStream from 'src/hooks/useStream';
import SettingsClient from 'src/utils/GQLExamClient';
import fetchDocumentImage from 'src/components/get_document_image_report';

require('dotenv').config();

const initialAuthState = {
  stream: '',
  isInitialised: false,
  image: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STREAM': {
      const { stream, isInitialised, image } = action.payload;
      return {
        ...state,

        stream,
        isInitialised,
        image
      };
    }

    default: {
      return { ...state };
    }
  }
};

const StreamContext = createContext({
  ...initialAuthState,
  setStream: () => Promise.resolve(),
  getStreams: () => Promise.resolve()
});

export const StreamProvider = ({ children }) => {
  // const { setStream } = useStream();
  const isMountedRef = useIsMountedRef();
  let bucketName = 'erpinstitution';
  const s3 = new AWS.S3();
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const setStream = async (app, id, stream_code) => {
    try {
      dispatch({
        type: 'SET_STREAM',
        payload: {
          stream: '',
          isInitialised: false,
          image: null
        }
      });
      const ADD = gql`
        mutation MyMutation(
          $app_name: String
          $session_key: String
          $session_value: String
        ) {
          add_session_value(
            app_name: $app_name
            session_key: $session_key
            session_value: $session_value
          ) {
            _id
          }
        }
      `;

      const { data } = await client
        .mutate({
          mutation: ADD,
          variables: {
            app_name: app,
            session_key: id,
            session_value: stream_code
          }
        })
        .then(res => {
          window.location.reload();

          return res;
        });
    } catch (err) {
      // console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      // console.log(err.message);
    }
  };
  const getStreams = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          user_by_email_tenant {
            _id
            session_details {
              app_name
              session_key
              session_value
            }
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        // console.log('user_by_email_tenant', data.user_by_email_tenant);
        const value = data.user_by_email_tenant.session_details.find(
          x => x.app_name == 'exam' && x.session_key == 'schedule'
        );
        console.log(value)
        try {
         const getstudents = gql`
                  query MyQuery( $exam_type: String!) {
                    get_exam_schedules(
                     
                      exam_type: $exam_type
                    ) {
                      _id
                      exam_mode
                      end_date
                      academic_year
                      exam_name
                      institution_id
                      exam_type
                      start_date
                      term_array
                      result_entry
                      exam_category
                    }
                  }
                `;

          const { data } = await SettingsClient
            .query({
              query: getstudents,
              variables: {
  
                exam_type: "external"
              },
              fetchPolicy: 'network-only'
            })
            .then(res => {
              // console.log(res);

              return res;
            });
          // console.log(value);

          if (!value) {
            const value2 = data.get_exam_schedules[0];
            console.log(value2)

            await setStream('exam', 'schedule', value2._id);
            // window.location.reload();
          } else {
            if (isMountedRef.current) {
              const value1 = data.get_exam_schedules.find(
                x => x._id == value.session_value
              );
console.log(value1)
dispatch({
  type: 'SET_STREAM',
  payload: {
    stream: value1,
    isInitialised: true,
    image: null
  }
});
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getStreams();
  }, [getStreams]);

  return (
    <StreamContext.Provider
      value={{
        ...state,
        setStream,
        getStreams
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export default StreamContext;
