import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CastForEducationOutlined from '@material-ui/icons/CastForEducationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useHistory } from 'react-router-dom';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 500,
    MaxHeight: 800
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  statusField: {
    fontSize: 16,
    color: 'textPrimary',
    textDecoration: 'none'
    // variant:"h3"
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  let history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" ref={ref} 
            onClick={() =>  history.push('/app/admin/settings/user_management')}
            >
          <SvgIcon>
            <SettingsOutlinedIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            General Settings
          </Typography>
        </Box>
        <Divider />
        <Box mt={1} />
        <Box pl={2} pb={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <BusinessIcon />
          </SvgIcon>
          <Box mr={2} />
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/general`
              }}
            >
              Institution
            </Typography>
            {/* <Typography
              variant="body2"
              color="textSecondary"             
            >
            This section provides the information about the Organisation Info.
            </Typography> */}
          </Box>
        </Box>
        <Box pl={2} pb={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <SwapVerticalCircleOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/user_management`
              }}
            >
              User Management
            </Typography>
            {/* <Typography
              variant="body2"
              color="textSecondary"             
            >
            This section provides the information about the Organisation Info.
            </Typography> */}
          </Box>
        </Box>
        
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <DescriptionIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/templates`
              }}
            >
              Notification Templates
            </Typography>
            {/* <Typography
              variant="body2"
              color="textSecondary"             
            >
            This section provides the information about the Organisation Info.
            </Typography> */}
          </Box>
        </Box>
        <Divider />
        {/* <Box mt={1} />
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}

              // component={RouterLink}
              // to={{
              //   pathname:`/app/admin/settings`,
              // }}
            >
              Academic
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <ClassOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/programs`
              }}
            >
              Programs
            </Typography>
          
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <CastForEducationOutlined />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/quota`
              }}
            >
              Quota
            </Typography>
         
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <ViewStreamOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/stream`
              }}
            >
              Stream
            </Typography>
           
          </Box>
        </Box>{' '}
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <GroupWorkOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/batch`
              }}
            >
              Batch
            </Typography>
          
          </Box>
        </Box>{' '}
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <DateRangeIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/academicyear`
              }}
            >
              Academic Year
            </Typography>
          
          </Box>
        </Box>
        <Divider /> */}
        
        
        <Box mt={1} />
        <Box pl={2} pb={2} alignItems="center" display="flex">
          {/* <Box mr={2} /> */}
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
            >
              Exam Settings
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <GroupWorkOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/schedule`
              }}
            >
              Exam Schedule
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <SupervisorAccountOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/fee_settings/fee_type`
              }}
            >
              Fee Type
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <DateRangeIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/feeschedule`
              }}
            >
              Fee Schedule
            </Typography>
          </Box>
        </Box>
      
        <Divider />
        
        
        <Box mt={1} />
        <Box pl={2} pb={2} alignItems="center" display="flex">
          {/* <Box mr={2} /> */}
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
            >
              Seating Configuration
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <ViewStreamOutlinedIcon />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/room_settings/Room`
              }}
            >
              Room Configuration
            </Typography>
          </Box>
        </Box>
        <Box pl={2} pb={2} alignItems="center" display="flex">
          <SvgIcon color="action">
            <CastForEducationOutlined />
          </SvgIcon>
          <Box mr={2} />

          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.statusField}
              component={RouterLink}
              to={{
                pathname: `/app/admin/settings/room_settings/Block`
              }}
            >
              Block Configuration
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
