// DashboardContext.js
import React from 'react';
import { createContext } from 'react';

const ModalExecutionContext = createContext();



export const ModalExecutionContextProvider = ({ children }) => {
  const [modalSubmitted, setModalSubmitted] = React.useState(false);

  const contextValue = {
    modalSubmitted,
    setModalSubmitted,
  };

  return (
    <ModalExecutionContext.Provider value={contextValue}>
      {children}
    </ModalExecutionContext.Provider>
  );
};
export default ModalExecutionContext;