import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
//import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
//import SettingsNotification from 'src/components/SettingsNotification';
import InternetConnection from 'src/components/InternetConnection';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { FeeSetupProvider } from 'src/contexts/FeeSetupContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { createCustomTheme} from 'src/theme5';
import routes, { renderRoutes } from 'src/routes';
import { StreamProvider } from 'src/contexts/StreamContext';
import { YearProvider } from 'src/contexts/YearContext';
import { TermProvider } from 'src/contexts/TermContext';
import { ImportCompanySetupProvider } from 'src/contexts/ImportForCompanyContext';
import {  ThemeProvider as NewThemeProvider  } from '@mui/material/styles';
import { ModalExecutionContextProvider } from 'src/contexts/ModalExecution';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
console.log(navigator.onLine);
const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const newTheme =createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  return (
    <NewThemeProvider theme={newTheme}>
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense={false} maxSnack={1}>
            <Router history={history}>
              <AuthProvider>
                <ImportCompanySetupProvider>
                <ModalExecutionContextProvider>
                  <FeeSetupProvider>
                    <StreamProvider>
                      <TermProvider>
                        <YearProvider>
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          {/* <CookiesNotification />
                <SettingsNotification /> */}
                          <InternetConnection />
                          {renderRoutes(routes)}
                        </YearProvider>
                      </TermProvider>
                    </StreamProvider>
                  </FeeSetupProvider>
                 </ModalExecutionContextProvider>
                </ImportCompanySetupProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
    </NewThemeProvider>
  );
};

export default App;
