import React ,{useState,useEffect} from 'react';
import {
  Box,
  makeStyles,
  List,
  ListSubheader,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LabelItem from './NavItem';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
const fullScreenHeight = window.innerWidth - 512;
const fullScreenHeight1 = window.innerWidth - 256;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    },
  wrapper: {
    display: 'flex',
    flexGrow: 1, 
    paddingLeft : 256,
    scrollBehavior: 'smooth',

  },
  sidebar: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64, 
    bottom: 0, 
    left: 256, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
   
  },
  sidebarN: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    position: 'fixed', 
    top: 64, 
    bottom: 0, 
    left: 256, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
   
  },
  sidebar1: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64,
    bottom: 0, 
    left: 0, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
  },
  sidebar1N: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    position: 'fixed', 
    top: 64,
    bottom: 0, 
    left: 0, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
  },
  content: {
    flexGrow: 1,
    scrollBehavior: 'smooth',
    maxWidth:fullScreenHeight

  },
  content1: {
    flexGrow: 1,
    scrollBehavior: 'smooth',
    maxWidth:fullScreenHeight1

  },
}));

const Applications = ({ children }) => {
  const classes = useStyles();
// const { open, setOpen } = useDashboardContext();
// const open = window.localStorage.getItem('drawer')
// console.log(open)

const Drawer = window.localStorage.getItem('drawer')
useEffect(() => {
  // Update the local state when Drawer changes
  setD(Drawer);
}, [Drawer]);
  window.addEventListener('storage', () => {
    // When local storage changes, dump the list to
    // the console.
    setD(window.localStorage.getItem('drawer'))
   
    console.log(JSON.parse(window.localStorage.getItem('drawer')));
  });

  const [open ,setD] = useState(Drawer)
console.log(open)
console.log(Drawer)

  const Users = {
    // subheader: 'Admission Settings Panel',
    items: [
      {
        title: 'General Settings',
        href: '/app/admin/settings',
        icon: 'fluent:settings-24-regular',
        items: [
          {
            title: 'Institution',
            href: '/app/admin/settings/general',
            // icon: 'ic:outline-assignment-turned-in'
          },
          {
            title: 'User Management',
            href: '/app/admin/settings/user_management',
            // icon: 'ri:building-line'
          },
          {
            title: 'Notification Templates',
            href: '/app/admin/settings/templates',
          }
        
        ]
      },
      
      {
        title: 'Exam Settings',
        href: '/app/admin/settings',
        icon: 'bx:spreadsheet',
        items:[
          {
            title: 'Exam Schedule',
            href: '/app/admin/settings/schedule',
          },
          {
            title: 'Fee Type',
            href: '/app/admin/settings/fee_settings/fee_type',
          },
          {
            title: 'Fee Schedule',
            href: '/app/admin/settings/feeschedule',
          }
          
         
        ]
      },
      {
        title: 'Seating Configuration',
        href: '/app/admin/settings',
        icon: 'material-symbols-light:event-seat-outline-rounded',
        items: [
          {
            title: 'Room Configuration',
            href: '/app/admin/settings/room_settings/Room',
          },
          {
            title: 'Block Configuration',
            href: '/app/admin/settings/room_settings/Block',
          },
          
        ]
      },
      
     
     
      // {
      //   title: 'Academic',
      //   href : '/app/admin/user_management',
      //   icon: SwapVerticalCircleOutlinedIcon,
  
      // },
  
      // {
      //   title: 'Quick Admission',
      //   href: '/app/admin/settings/quick_admission',
      //   icon: AssignmentTurnedInOutlinedIcon
      // },
    ]
  };

  const UsersN = {
    // subheader: 'Admission Settings Panel',
    items: [
    
     
          {
            title: 'Accounts',
            href: '/app/fee/settings/fee_settings/accounts',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Category',
            href: '/app/fee/settings/fee_settings/fee_categories',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Type',
            href: '/app/fee/settings/fee_settings',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Structure',
            href: '/app/fee/settings/fee_settings/fee_structure',
            // icon: 'iconamoon:settings'
          },
          {
            title: 'Fee Schedule',
            href: '/app/fee/settings/fee_settings/feeschedule',
            // icon: 'iconamoon:settings'
          }
      
    
      // {
      //   title: 'Academic',
      //   href : '/app/admin/user_management',
      //   icon: SwapVerticalCircleOutlinedIcon,
  
      // },
  
      // {
      //   title: 'Quick Admission',
      //   href: '/app/admin/settings/quick_admission',
      //   icon: AssignmentTurnedInOutlinedIcon
      // },
    ]
  };
  let history = useHistory();

  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <LabelItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
            
          })}
        </LabelItem>
      );
    } else {
      acc.push(
        <LabelItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  }

  const location = useLocation();

  const content = (
         <Box  px={2} pb ={2}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>

  );
  const contentN = (
   <Box px={2} py={1}>
    <Box display={'flex'} mb={1}  alignItems={'center'} style={{cursor:'pointer'}} onClick={()=>history.push('/app/fee/schedule')}>
      <Icon icon={'material-symbols:arrow-back-ios'} fontSize={'17px'}/>
      <Box mr={0.8}/>
      <Typography variant='body1' color='textPrimary' style={{fontWeight:500}}>
        Back to main menu
      </Typography>
    </Box>
    <Box  px={2} pb ={2}>
     <List
       key={UsersN.subheader}
       subheader={
         <ListSubheader disableGutters disableSticky>
           {UsersN.subheader}
         </ListSubheader>
       }
     >
       {renderNavItems({
         items: UsersN.items,
         pathname: location.pathname
       })}
     </List>
   </Box>
   
   
   </Box>

);
  return (
    <div className={classes.root}>
    {/* <Box className={open ? window.location.pathname.includes('fee_settings')? classes.sidebarN : classes.sidebar : window.location.pathname.includes('fee_settings')? classes.sidebar1N:classes.sidebar1}> */}
    <Box className={open=='true' ? classes.sidebar : classes.sidebar1}>

      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        {/* <List>{window.location.pathname.includes('fee_settings')?contentN:content}</List> */}
        <List>{content}</List>

      {/* </PerfectScrollbar> */}
    </Box>
    <Box className={classes.wrapper}>
      <Box className={open=='true'?classes.content:classes.content1} >
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        {children}
        {/* </PerfectScrollbar> */}
        </Box>
    </Box>
  </div>
  );
};

Applications.propTypes = {
  children: PropTypes.node
};
export default Applications;
