import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from 'react';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import client from 'src/utils/GQLCompanyClient';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Settingclient from 'src/utils/GQLSettingsClient';

require('dotenv').config();

const initialAuthState = {
  year: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_YEAR': {
      const { year } = action.payload;
      return {
        ...state,
        year
      };
    }

    default: {
      return { ...state };
    }
  }
};

const YearContext = createContext({
  ...initialAuthState,
  setYear: () => Promise.resolve(),
  getYear: () => Promise.resolve()
});

export const YearProvider = ({ children }) => {
  // const { setYear } = useStream();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const setYear = async (app, id, year) => {
    dispatch({
      type: 'SET_YEAR',
      payload: {
        year: null
      }
    });
    try {
      const ADD = gql`
        mutation MyMutation(
          $app_name: String
          $session_key: String
          $session_value: String
        ) {
          add_session_value(
            app_name: $app_name
            session_key: $session_key
            session_value: $session_value
          ) {
            _id
          }
        }
      `;

      const { data } = await client
        .mutate({
          mutation: ADD,
          variables: {
            app_name: app,
            session_key: id,
            session_value: year
          }
        })
        .then(res => {
          console.log(res);
          history.push('/app/exam/home');

          window.location.reload();
          return res;
        });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };
  const getYear = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          user_by_email_tenant {
            _id
            session_details {
              app_name
              session_key
              session_value
            }
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          // console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        const value = data.user_by_email_tenant.session_details.find(
          x =>
            x.app_name == 'exam' &&
            x.session_key == 'Academic Year'
        );
        console.log('user_by_email_tenant', value);
        if (!value) {
          try {
            const getstudents = gql`
              query MyQuery {
                get_all_academic_years {
                  year_name
                  _id
                  status
                  term_type
                }
              }
            `;

            const { data } = await Settingclient.query({
              query: getstudents,

              fetchPolicy: 'network-only'
            }).then(res => {
              console.log(res);
              return res;
            });

            if (isMountedRef.current) {
              const active = data.get_all_academic_years.find(
                x => x.status == 'active'
              );
              // await setYear(
              //   'exam',
              //   'Academic Year',
              //   active._id || data.get_all_academic_years[0]._id
              // );
              window.location.reload();
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          if (isMountedRef.current) {
            try {
              const getstudents = gql`
                query MyQuery {
                  get_all_academic_years {
                    year_name
                    _id
                    status
                    term_type
                  }
                }
              `;

              const { data } = await Settingclient.query({
                query: getstudents,

                fetchPolicy: 'network-only'
              }).then(async res => {
                console.log(res);
                const value1 = res.data.get_all_academic_years.find(
                  x => x._id == value.session_value
                );
                console.log('value1', value1);
                if(!value1){
                  try {
                    const getstudents = gql`
                      query MyQuery {
                        get_all_academic_years {
                          year_name
                          _id
                          status
                          term_type
                        }
                      }
                    `;
        
                    const { data } = await Settingclient.query({
                      query: getstudents,
        
                      fetchPolicy: 'network-only'
                    }).then(res => {
                      console.log(res);
                      return res;
                    });
        
                    if (isMountedRef.current) {
                      const active = data.get_all_academic_years.find(
                        x => x.status == 'active'
                      );
                      // await setYear(
                      //   'exam',
                      //   'Academic Year',
                      //   active._id || data.get_all_academic_years[0]._id
                      // );
                      window.location.reload();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }else{
                console.log('value1', value1);

                dispatch({
                  type: 'SET_YEAR',
                  payload: {
                    year: value1
                  }
                });
              }
                return res;
              });
            } catch (err) {
              console.error(err);
            }
            const value1 = data.get_all_academic_years.find(
              x => x._id == value.session_value
            );
            // console.log('value1',value1);
            // dispatch({
            // type: 'SET_YEAR',
            // payload: {
            //   year: value1
            // }
            // });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getYear();
  }, [getYear]);

  return (
    <YearContext.Provider
      value={{
        ...state,
        setYear,
        getYear
      }}
    >
      {children}
    </YearContext.Provider>
  );
};

export default YearContext;
